import * as React from "react";

/**
 * Simple React hook that return a boolean;
 *
 * - `true` at the mount time
 * - Then always `false`
 *
 * @example
 * import { useIsFirstRender } from '@griddo/core'
 *
 * export default function Component() {
 *   const isFirst = useIsFirstRender()
 *   const [data, setData] = useState<number>(0)
 *
 *   useEffect(() => {
 *     console.log('Normal useEffect', { data })
 *   }, [data])
 *
 *   return (
 *     <div>
 *       <p>Open your console</p>
 *       <p>Is first render: {isFirst ? 'yes' : 'no'}</p>
 *       <button onClick={() => setData(Date.now())}>Update data</button>
 *     </div>
 *   )
 * }
 *
 * @see
 * https://usehooks-ts.com for more hooks
 *
 */
function useIsFirstRender(): boolean {
	const isFirst = React.useRef(true);

	if (isFirst.current) {
		isFirst.current = false;

		return true;
	}

	return isFirst.current;
}

export { useIsFirstRender };
